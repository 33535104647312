import * as React from "react";
import "./Less/Navbar.css";

import logo from "../../../../assets/logo.png";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { DataContext } from "../../../../contexts/Data.context";

interface IProps {
    mobileNavbarButton: boolean;
    navbarComplexity: string;
    dogName?: string;
    navbarState: boolean;
    clickedNavbarButton: boolean;
    functionWrapper: () => void;
    switchingBackNavbar: () => void;
    closingOrOpeningDroppdown: (closing: boolean, buttonIsClicked: boolean) => void;
}

export const NavbarView: React.FC<IProps> = (props) => {
    const { showBreeds, setShowBreeds } = React.useContext(DataContext);
    //normal navbar classNames
    let navbarClassName: string[] = ["spec-dog", "show"];
    let specdogSecClassName: string[] = ["spec-dog-2", "hide"];
    let brandSectionClassName: string[] = ["brand-section", "brand-section--sp"];
    let brandLogoClassName: string[] = ["brand-section__dog-logo", "brand-section__dog-logo--sp"];
    let brandTitleClassName: string[] = [
        "brand-section__page-title",
        "brand-section__page-title--sp"
    ];
    //mobile-navbar classNames
    let mobileNavClassName: string[] = ["mobile-navbar"];
    let mobileNavButtonClassName: string[] = ["btn-container__nav-btn"];
    let mobileNavLinks: string[] = ["mobile-links", "hide-mobile-links"];
    let mobileNavBackdropClassName: string[] = ["backdrop-navbar"];

    //this is responsible for changing the 2 navbars
    if (!props.clickedNavbarButton && !props.navbarState) {
        navbarClassName = ["spec-dog", "hide"];
        specdogSecClassName = ["spec-dog-2", "show"];
    }
    //main-page
    if (props.navbarComplexity === "main-page") {
        navbarClassName = ["main-page"];
        brandSectionClassName = ["brand-section", "brand-section--mp"];
        brandLogoClassName = ["brand-section__dog-logo"];
        brandTitleClassName = ["brand-section__page-title"];
        mobileNavClassName = ["mobile-navbar", "mobile-navbar--main"];
        mobileNavButtonClassName = ["btn-container__nav-btn", "btn-container__nav-btn--black"];
        mobileNavBackdropClassName = ["backdrop-navbar", "backdrop-navbar--main"];
    }
    //events/event-page
    if (props.navbarComplexity === "simple") {
        navbarClassName = ["spec-dog"];
        mobileNavBackdropClassName = ["backdrop-navbar", "backdrop-navbar--event"];
    }
    //show-close dropdown menu
    if (props.mobileNavbarButton) {
        mobileNavLinks = ["mobile-links", "show-mobile-links"];
    }

    return (
        <div className="navbar-container">
            <nav className={navbarClassName.join(" ")}>
                <NavLink className={brandSectionClassName.join(" ")} to="/">
                    <img src={logo} alt="dog-logo" className={brandLogoClassName.join(" ")} />
                    <h3 className={brandTitleClassName.join(" ")}>A kilenc magyar kutya</h3>
                </NavLink>

                {props.navbarComplexity !== "main-page" && (
                    <div className="links" onClick={() => setShowBreeds(!showBreeds)}>
                        Kutyafajták
                    </div>
                )}

                <NavLink className="links" to="/events">
                    Események
                </NavLink>
                <NavLink className="links" to="/organisations">
                    Szervezetek
                </NavLink>
                <HashLink className="links" smooth to={{ pathname: "/", hash: "#about" }}>
                    Rólunk
                </HashLink>
            </nav>

            {props.navbarComplexity === "complex" && (
                <nav className={specdogSecClassName.join(" ")}>
                    <NavLink className="brand-section brand-section--sp-2" to="/">
                        <img className="brand-section__dog-logo-mini" src={logo} alt="logo" />
                        <h3 className="brand-section__page-title brand-section__page-title--sp-2">
                            {props.dogName ? props.dogName : "9 magyar kutya"}
                        </h3>
                    </NavLink>{" "}
                    <ul className="link-container">
                        <li className="link-item">
                            <HashLink className="links links--white" smooth to="#intro">
                                Bemutatkozás
                            </HashLink>
                        </li>
                        <li className="link-item">
                            <HashLink className="links links--white" smooth to="#origin">
                                Eredete
                            </HashLink>
                        </li>
                        <li className="link-item">
                            <HashLink className="links links--white" smooth to="#props">
                                Jellemzők
                            </HashLink>
                        </li>
                        <li className="link-item">
                            <HashLink className="links links--white" smooth to="#desc">
                                Fajta leírás
                            </HashLink>
                        </li>
                        <li className="link-item">
                            <HashLink className="links links--white" smooth to="#gallery">
                                Galéria
                            </HashLink>
                        </li>
                    </ul>
                    <div className="btn-container" onClick={props.switchingBackNavbar}>
                        <button className="btn-container__nav-btn" />
                    </div>
                </nav>
            )}
            <div>
                <nav className={mobileNavClassName.join(" ")}>
                    <div className="brand-section brand-section--sp-2">
                        <img className="brand-section__dog-logo-mini" src={logo} alt="logo" />
                        <h3 className="brand-section__page-title brand-section__page-title--sp-2">
                            {props.dogName ? props.dogName : "9 magyar kutya"}
                        </h3>
                    </div>{" "}
                    <div
                        className="btn-container"
                        onClick={() => props.closingOrOpeningDroppdown(true, true)}
                    >
                        <button className={mobileNavButtonClassName.join(" ")} />
                    </div>
                </nav>
                <nav className={mobileNavLinks.join(" ")}>
                    <ul className="mobile-links-container">
                        <li className="link-item">
                            {props.navbarComplexity !== "main-page" && (
                                <NavLink className="links links--white" to="/">
                                    Kutyafajták
                                </NavLink>
                            )}
                        </li>
                        <li className="link-item">
                            <NavLink className="links links--white" to="/events">
                                Események
                            </NavLink>
                        </li>
                        <li className="link-item">
                            <NavLink className="links links--white" to="/organisations">
                                Szervezetek
                            </NavLink>
                        </li>
                        <li className="link-item">
                            <HashLink
                                className="links links--white"
                                smooth
                                to={{ pathname: "/", hash: "#about" }}
                            >
                                Rólunk
                            </HashLink>
                        </li>
                        {props.navbarComplexity === "complex" && (
                            <div>
                                <li className="underline" />
                                <li className="link-item">
                                    <HashLink
                                        className="links links--white"
                                        to="#intro"
                                        smooth
                                        onClick={() =>
                                            props.closingOrOpeningDroppdown(false, false)
                                        }
                                    >
                                        Bemutatkozás
                                    </HashLink>
                                </li>
                                <li className="link-item">
                                    <HashLink
                                        className="links links--white"
                                        to="#origin"
                                        smooth
                                        onClick={() =>
                                            props.closingOrOpeningDroppdown(false, false)
                                        }
                                    >
                                        Eredete
                                    </HashLink>
                                </li>
                                <li className="link-item">
                                    <HashLink
                                        className="links links--white"
                                        to="#props"
                                        smooth
                                        onClick={() =>
                                            props.closingOrOpeningDroppdown(false, false)
                                        }
                                    >
                                        Jellemzők
                                    </HashLink>
                                </li>
                                <li className="link-item">
                                    <HashLink
                                        className="links links--white"
                                        to="#desc"
                                        smooth
                                        onClick={() =>
                                            props.closingOrOpeningDroppdown(false, false)
                                        }
                                    >
                                        Fajta leírás
                                    </HashLink>
                                </li>
                                <li className="link-item">
                                    <HashLink
                                        className="links links--white"
                                        to="#gallery"
                                        smooth
                                        onClick={() =>
                                            props.closingOrOpeningDroppdown(false, false)
                                        }
                                    >
                                        Galéria
                                    </HashLink>
                                </li>
                            </div>
                        )}
                    </ul>
                </nav>
                {props.mobileNavbarButton && (
                    <div
                        className={mobileNavBackdropClassName.join(" ")}
                        onClick={() => props.closingOrOpeningDroppdown(false, false)}
                    />
                )}
            </div>
        </div>
    );
};
