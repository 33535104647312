import React, { useEffect, useState } from "react";
import { IError, ILoading } from "xa-generics";
import OrganisationsDAO from "../DAO/Organisations.dao";
import OrganisationModel from "../Model/Organisation.model";
import OrganisationsView from "../View/Organisation.view";

const OrganisationsController = () => {
    const [loading, setLoading] = useState<ILoading>(true);
    const [error, setError] = useState<IError>(null);
    const [org, setOrg] = useState<OrganisationModel[]>([]);

    useEffect(
        () => {
            setLoading(
                OrganisationsDAO.getOrganisations()
                    .then((data) => {
                        setOrg(data);
                    })
                    .catch(() => {
                        setError(error);
                    })
                    .finally(() => {
                        setLoading(false);
                    })
            );
        },
        //eslint-disable-next-line
        []
    );

    return <OrganisationsView org={org} error={error} loading={loading} />;
};

export default OrganisationsController;
