import { axios, IDynamicObject } from "xa-generics";
import { AxiosPromise, AxiosResponse } from "axios";
import OrganisationModel from "../Model/Organisation.model";

export default class OrganisationsDAO {
    public static async getOrganisations(): Promise<OrganisationModel[]> {
        const request: AxiosPromise<IDynamicObject[]> = axios
            .getInstance()
            .get("api/organisations");
        const response: AxiosResponse<IDynamicObject[]> = await request;
        return response.data.map((item: IDynamicObject) => new OrganisationModel(item));
    }
}
