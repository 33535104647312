import { IDynamicObject, setString } from "xa-generics";
import { BlogEventModel } from "./BlogEvent.model";

export class EventModel extends BlogEventModel {
    constructor(data?: IDynamicObject) {
        super(data);
        if (!data) return;
        this.long_desc = setString(data.long_desc);
    }
    long_desc: string = ""; //HTML
}
