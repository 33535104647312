import { IDynamicObject } from "xa-generics";
import { DogVideoModel } from "./DogVideo.model";
import { DogImageModel } from "./DogImage.model";
import { DogModel } from "./Dog.model";

export class FullDogModel {
    constructor(data?: IDynamicObject<IDynamicObject<any> | IDynamicObject<any>[]>) {
        if (!data) return;
        this.dog = new DogModel(data.dog as IDynamicObject);
        if (data.images instanceof Array) {
            this.images = data.images.map((img) => new DogImageModel(img));
        }
        if (data.videos instanceof Array) {
            this.videos = data.videos.map((video) => new DogVideoModel(video));
        }
    }

    dog: DogModel = new DogModel();
    images: DogImageModel[] = [];
    videos: DogVideoModel[] = [];
}
