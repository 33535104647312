import React, { useContext } from "react";
import { IProjectState } from "../Controller/MainPageController";
import { DataContext } from "../../../contexts/Data.context";
import DogBreedMontage from "../../DogBreeds/DogBreedsView";
import Parser from "html-react-parser";
import Footer from "../../Layout/Footer/FooterView";
import Navbar from "../../Layout/Navbar/Controller/NavbarController";
import "./Less/MainPage.css";

interface IProps extends IProjectState {}

export const MainPageView: React.FC<IProps> = (props) => {
    const { info } = useContext(DataContext);

    return (
        <div>
            <div className="dummy-div-to-the-top" />
            <Navbar navbarComplexity="main-page" />
            <DogBreedMontage sliding={false} />

            <div id="about" className="about-container">
                <h3 className="title-of-content">{info.name}</h3>
                <p className="text-of-content">{Parser(info.text.replace(/\n/g, "<br/>"))}</p>
            </div>

            <Footer />
        </div>
    );
};
