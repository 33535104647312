import { DogOriginModel } from "./DogOrigin.model";
import { DogGeneralModel } from "./DogGeneral.model";
import { DogFeaturesModel } from "./DogFeatures.model";
import { DogPropertiesModel } from "./DogProperties.model";

export class DogMiscModel {
    constructor(
        general: DogGeneralModel,
        properties: DogPropertiesModel,
        features: DogFeaturesModel,
        origin: DogOriginModel
    ) {
        this.general = general;
        this.properties = properties;
        this.features = features;
        this.origin = origin;
    }

    general: DogGeneralModel;
    properties: DogPropertiesModel;
    features: DogFeaturesModel;
    origin: DogOriginModel;
}
