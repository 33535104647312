import { IDynamicObject, ModelConstructor } from "xa-generics";

class OrganisationModel {
    constructor(data?: IDynamicObject) {
        if (!data) return;
        ModelConstructor(data, this);
    }

    name: string = "";
    image: string = "";
    description: string = "";
    website: string = "";
}

export default OrganisationModel;
