import { IDynamicObject, ModelConstructor } from "xa-generics";

export class DogPropertiesModel {
    constructor(data?: IDynamicObject) {
        if (!data) return;
        ModelConstructor(data, this);
    }

    dog_id: string = "";
    physique_bg: string = "";
    fur_bg: string = "";
    mind_bg: string = "";
}
