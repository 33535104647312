import * as React from "react";
import { MainPageView } from "../View/MainPageView";

interface IProjectProps {
    history: any;
    location: any;
    match: any;
    staticContext?: any;
}

export interface IProjectState {}

class PageController extends React.Component<IProjectProps, IProjectState> {
    render() {
        return (
            <div>
                <MainPageView />
            </div>
        );
    }
}

export default PageController;
