import { IDynamicObject, ModelConstructor } from "xa-generics";

export class DogImageModel {
    constructor(data?: IDynamicObject) {
        if (!data) return;
        ModelConstructor(data, this);
    }

    image_id: string = "";
    image: string = "";
    is_main: boolean = false;
    is_cover: boolean = false;
}
