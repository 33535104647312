import * as React from "react";
import EventView from "../View/EventView";
import { IError, ILoading, IMatch } from "xa-generics";
import { EventModel } from "../../EventsPage/Model/Event.model";
import { EventDAO } from "../../EventsPage/DAO/Event.dao";

interface IEventProps {
    history: any;
    location: any;
    match: IMatch<{ eventID: string }>;
    staticContext?: any;
}

interface IEventState {
    showMontage: boolean;
    loading: ILoading;
    data: EventModel;
    error: IError;
    id: string;
}

class EventController extends React.Component<IEventProps, IEventState> {
    state: IEventState = {
        data: new EventModel(),
        showMontage: false,
        loading: true,
        error: null,
        id: this.props.match.params.eventID
    };

    componentDidMount() {
        this.loadEvent();
    }

    loadEvent = () => {
        if (this.state.loading) this.setState({ loading: true });
        EventDAO.loadEventByID(this.state.id)
            .then((data) => this.setState({ data }))
            .catch((error: IError) => this.setState({ error }))
            .finally(() => this.setState({ loading: false }));
    };

    dogBreedsClicked = () => {
        if (window.innerWidth <= 600) {
            document.body.style.overflow = "hidden";
        }
        this.setState({ showMontage: true });
    };

    closeMontage = () => {
        document.body.style.overflowY = "scroll";
        this.setState({ showMontage: false });
    };

    render() {
        return <EventView {...this.state} closeMontage={this.closeMontage} />;
    }
}

export default EventController;
