import { IDynamicObject, ModelConstructor } from "xa-generics";

export class DogGeneralModel {
    constructor(data?: IDynamicObject) {
        if (!data) return;
        ModelConstructor(data, this);
    }

    dog_id: string = "";
    menu_img: string = "";
    hero_img: string = "";
    slogan: string = "";
}
