import { axios, IDynamicObject } from "xa-generics";
import { AxiosPromise, AxiosResponse } from "axios";
import { BlogEventModel } from "../Model/BlogEvent.model";
import { EventModel } from "../Model/Event.model";

export abstract class EventDAO {
    private static readonly base: string = "/api/blogs";

    public static async loadEvents(): Promise<BlogEventModel[]> {
        const request: AxiosPromise<IDynamicObject[]> = axios.getInstance().get(`${this.base}`);
        const response: AxiosResponse<IDynamicObject[]> = await request;
        return response.data.map((item) => new BlogEventModel(item));
    }

    public static async loadEventByID(id: string): Promise<EventModel> {
        const request: AxiosPromise<IDynamicObject> = axios.getInstance().get(`${this.base}/${id}`);
        const response: AxiosResponse<IDynamicObject> = await request;
        return new EventModel(response.data);
    }
}
