import * as React from "react";
import { BlogEventModel } from "../Model/BlogEvent.model";
import { NavLink } from "react-router-dom";
import Footer from "../../Layout/Footer/FooterView";
import Navbar from "../../Layout/Navbar/Controller/NavbarController";
import DogBreeds from "../../DogBreeds/DogBreedsView";
import "./Less/Events.css";

interface IProps {
    events: BlogEventModel[];
    showMontage: boolean;
    closeMontage: () => void;
}

const eventsView: React.FC<IProps> = (props) => {
    return (
        <div>
            <Navbar navbarComplexity="simple" />
            <DogBreeds sliding={true} />

            <div className="events-page">
                <header className="top-section">
                    <h1 className="top-section__title">Események</h1>
                </header>
                <section className="events-container">
                    {props.events.map((event) => (
                        <div className="event-card" key={event.blog_id}>
                            <div className="event-card__image-container">
                                <img
                                    className="event-card__event-image"
                                    src={event.hero_img}
                                    alt="event"
                                />
                            </div>
                            <h2 className="event-card__event-title">{event.title}</h2>
                            <p className="event-card__event-short-text">{event.short_desc}</p>
                            <div className="event-card__footer">
                                <p className="event-card__event-date">{event.published_at}</p>
                                <NavLink
                                    className="event-card__detailes-button"
                                    to={`/events/${event.blog_id}`}
                                >
                                    RÉSZLETEK
                                </NavLink>
                            </div>
                        </div>
                    ))}
                </section>
            </div>
            <Footer />
        </div>
    );
};

export default eventsView;
