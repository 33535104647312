import * as React from "react";
import Footer from "../../Layout/Footer/FooterView";
import Navbar from "../../Layout/Navbar/Controller/NavbarController";
import Parser from "html-react-parser";
import DogBreeds from "../../DogBreeds/DogBreedsView";
import { NavLink } from "react-router-dom";
import { EventModel } from "../../EventsPage/Model/Event.model";
import "../../EventsPage/View/Less/Events.less"; // where sould u put this kind of reusable components?
import "./Less/Event.css";

interface IProps {
    data: EventModel;
    showMontage: boolean;
    closeMontage: () => void;
}

const eventsView: React.FC<IProps> = (props) => {
    return (
        <div>
            <Navbar navbarComplexity="simple" />
            <DogBreeds sliding={true} />

            <div className="event-page">
                <header className="header-section">
                    <img
                        className="header-section__header-image"
                        src={props.data.hero_img}
                        alt="eventHeader"
                    />
                    <div className="header-section__bg-cover" />
                    <NavLink className="header-section__back-to-events-button" to="/events">
                        VISSZA
                    </NavLink>
                </header>
                <section className="event-data">
                    <div className="event-data__title">{props.data.title}</div>
                    <div className="event-data__published-at">{props.data.published_at}</div>
                    <div className="event-data__content">{Parser(props.data.long_desc)}</div>
                </section>
            </div>
            <Footer />
        </div>
    );
};

export default eventsView;
