import React from "react";
import ReactDOM from "react-dom";
import DefaultAxios from "axios";
import * as serviceWorker from "./serviceWorker";
import { axios, IDynamicObject } from "xa-generics";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

DefaultAxios.get("/api/rest")
    .then((response) => response.data)
    .then((envKeys: IDynamicObject) => {
        axios.initAxios(envKeys.REST_API_URL);
        ReactDOM.render(
            <BrowserRouter>
                <App />
            </BrowserRouter>,
            document.getElementById("root")
        );
    })
    .catch((error) => {});

serviceWorker.unregister();
