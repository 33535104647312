import { AxiosPromise, AxiosResponse } from "axios";
import { axios, IDynamicObject } from "xa-generics";
import { InfoModel } from "../Model/Info.model";

export abstract class InfoDAO {
    private static readonly base: string = "/api/info";

    public static async loadInfo(): Promise<InfoModel> {
        const request: AxiosPromise<IDynamicObject> = axios.getInstance().get(this.base);
        const response: AxiosResponse<IDynamicObject> = await request;
        return new InfoModel(response.data);
    }
}
