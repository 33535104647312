import React, { Context, createContext, useEffect, useState } from "react";
import { IError, ILoading } from "xa-generics";
import { IDynamicObject } from "xa-generics";
import { IDataContext } from "../interfaces/IDataContext.interface";
import { DogListModel } from "../models/DogList.model";
import { FullDogModel } from "../components/SpecDogPage/Model/FullDog.model";
import { DogMiscModel } from "../components/SpecDogPage/Model/DogMisc.model";
import { InfoModel } from "../components/MainPage/Model/Info.model";
import { InfoDAO } from "../components/MainPage/Dao/Info.dao";
import { DogsDAO } from "../dao/Dogs.dao";
import { clone } from "lodash";

/**
 * ## DataContext
 */
export const DataContext: Context<IDataContext> = createContext<IDataContext>(null as any);

DataContext.displayName = "DataContext";

interface IDataContextWrapperProps {}

/**
 * ## Data context wrapper component
 *
 */
export const DataContextWrapper: React.FC<IDataContextWrapperProps> = (props) => {
    const [dogs, setDogs] = useState<DogListModel[]>([]);
    const [info, setinfo] = useState<InfoModel>(new InfoModel());
    const [error, setError] = useState<IError>(null);
    const [loading, setLoading] = useState<ILoading>(false);
    const [showBreeds, setShowBreeds] = useState<boolean>(false);

    const [dogsByID, setDogsByID] = useState<
        IDynamicObject<{
            dog: FullDogModel;
            misc: DogMiscModel;
        }>
    >({});

    const loadDogs = (): void => {
        DogsDAO.loadDogs()
            .then((data) => {
                setDogs(data);
            })
            .catch((error: IError) => {
                setError(error);
            });
    };

    const loadInfo = (): void => {
        InfoDAO.loadInfo()
            .then((data) => {
                setinfo(data);
            })
            .catch((error: IError) => {
                setError(error);
            });
    };

    const init = (): void => {
        loadDogs();
        loadInfo();
    };
    useEffect(init, []);

    const fetchDogData = async (
        dogID: string
    ): Promise<{
        dog: FullDogModel;
        misc: DogMiscModel;
    }> => {
        const data = await Promise.all([DogsDAO.loadDogByID(dogID), DogsDAO.loadDogMisc(dogID)]);
        return {
            dog: data[0],
            misc: data[1]
        };
    };

    const getDogByID = (dogID: string, cb?: Function): void => {
        setLoading(
            fetchDogData(dogID)
                .then((data) => {
                    let dataClone = clone(dogsByID);
                    dataClone[dogID] = data;
                    setDogsByID(dataClone);
                    if (cb) cb();
                })
                .catch((error: IError) => {
                    setError(error);
                })
                .finally(() => setLoading(false))
        );
    };

    return (
        <DataContext.Provider
            value={{
                dogs,
                info,
                error,
                loading,
                dogsByID,
                showBreeds,
                getDogByID,
                setShowBreeds
            }}
        >
            <DataContext.Consumer>
                {(ctxValue: IDataContext) => props.children}
            </DataContext.Consumer>
        </DataContext.Provider>
    );
};
