import { DogMiscModel } from "./../components/SpecDogPage/Model/DogMisc.model";
import { DogListModel } from "../models/DogList.model";
import { FullDogModel } from "../components/SpecDogPage/Model/FullDog.model";
import { DogOriginModel } from "../components/SpecDogPage/Model/DogOrigin.model";
import { DogGeneralModel } from "../components/SpecDogPage/Model/DogGeneral.model";
import { DogFeaturesModel } from "../components/SpecDogPage/Model/DogFeatures.model";
import { DogPropertiesModel } from "../components/SpecDogPage/Model/DogProperties.model";
import { axios, IDynamicObject } from "xa-generics";
import { AxiosPromise, AxiosResponse } from "axios";

export abstract class DogsDAO {
    private static readonly base: string = "/api/dog";

    public static async loadDogs(): Promise<DogListModel[]> {
        const request: AxiosPromise<IDynamicObject[]> = axios.getInstance().get(`${this.base}/all`);
        const response: AxiosResponse<IDynamicObject[]> = await request;
        return response.data.map((dog) => new DogListModel(dog));
    }

    public static async loadDogByID(id: string): Promise<FullDogModel> {
        const request: AxiosPromise<IDynamicObject<
            IDynamicObject | IDynamicObject[]
        >> = axios.getInstance().get(`${this.base}/${id}`);
        const response: AxiosResponse<IDynamicObject<
            IDynamicObject | IDynamicObject[]
        >> = await request;
        return new FullDogModel(response.data);
    }

    public static async loadDogGeneral(id: string): Promise<DogGeneralModel> {
        const request: AxiosPromise<IDynamicObject> = axios
            .getInstance()
            .get(`${this.base}/${id}/general`);
        const response: AxiosResponse<IDynamicObject> = await request;
        return new DogGeneralModel(response.data);
    }

    public static async loadDogProperties(id: string): Promise<DogPropertiesModel> {
        const request: AxiosPromise<IDynamicObject> = axios
            .getInstance()
            .get(`${this.base}/${id}/properties`);
        const response: AxiosResponse<IDynamicObject> = await request;
        return new DogPropertiesModel(response.data);
    }

    public static async loadDogFeatures(id: string): Promise<DogFeaturesModel> {
        const request: AxiosPromise<IDynamicObject> = axios
            .getInstance()
            .get(`${this.base}/${id}/features`);
        const response: AxiosResponse<IDynamicObject> = await request;
        return new DogFeaturesModel(response.data);
    }

    public static async loadDogOrigin(id: string): Promise<DogOriginModel> {
        const request: AxiosPromise<IDynamicObject> = axios
            .getInstance()
            .get(`${this.base}/${id}/origin`);
        const response: AxiosResponse<IDynamicObject> = await request;
        return new DogOriginModel(response.data);
    }

    /**
     * ## Dog miscallenaous data
     *
     * This returns a combination of the dog features,
     * properties, origin, and general API responses.
     */
    public static async loadDogMisc(id: string): Promise<DogMiscModel> {
        const data = await Promise.all([
            this.loadDogGeneral(id),
            this.loadDogProperties(id),
            this.loadDogFeatures(id),
            this.loadDogOrigin(id)
        ]);
        return new DogMiscModel(data[0], data[1], data[2], data[3]);
    }
}
