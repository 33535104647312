import { IDynamicObject, ModelConstructor } from "xa-generics";

export class DogVideoModel {
    constructor(data?: IDynamicObject) {
        if (!data) return;
        ModelConstructor(data, this);
    }

    video_id: string = "";
    dog_id: string = "";
    video: string = "";
}
