import React from "react";
import { IError, ILoading } from "xa-generics";
import DogBreeds from "../../DogBreeds/DogBreedsView";
import Navbar from "../../Layout/Navbar/Controller/NavbarController";
import OrganisationModel from "../Model/Organisation.model";
import "./Less/organisations.css";

interface IOrganisationsViewProps {
    loading: ILoading;
    error: IError;
    org: OrganisationModel[];
}

const OrganisationsView = (props: IOrganisationsViewProps) => {
    return (
        <div className="organisations-list-component">
            <Navbar navbarComplexity="simple" />
            <DogBreeds sliding={true} />
            <div className="organisations-list-header">
                <div className="organisations-list-header__container">Szervezetek</div>
            </div>
            <div className="organisations-list">
                {props.loading && <div className="organisations-list__loading">Töltés...</div>}
                {props.error && (
                    <div className="organisations-list__error">
                        Valami hiba történt az adatok betöltése közben.
                    </div>
                )}
                {props.org.map((org) => (
                    <div className="org-item">
                        <img className="org-item__image" src={org.image} alt={org.name} />
                        <div className="org-item__name">{org.name}</div>
                        <div className="org-item__desc">{org.description}</div>
                        <a className="org-item__button" href={org.website}>
                            Irány a weboldal
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OrganisationsView;
