import { IDynamicObject, ModelConstructor } from "xa-generics";

export class InfoModel {
    constructor(data?: IDynamicObject) {
        if (!data) return;
        ModelConstructor(data, this);
    }

    document_id: string = "";
    name: string = "";
    short_desc: string = "";
    text: string = "";
}
