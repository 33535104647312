import * as React from "react";
import Parser from "html-react-parser";
import { DogOriginModel } from "../../SpecDogPage/Model/DogOrigin.model";
import { DogFeaturesModel } from "../../SpecDogPage/Model/DogFeatures.model";
import "./Less/Origin.css";

interface IProps {
    isOpen: boolean;
    data: DogOriginModel | DogFeaturesModel;
    closingMoreOriginInfo: () => void;
}

export const OriginView: React.FC<IProps> = ({ isOpen, data, closingMoreOriginInfo }) => {
    let containerClassName: string[] = ["more-origin-info-container", "hiding-container"];
    if (isOpen) {
        containerClassName = ["more-origin-info-container", "show-container"];
    }

    const getContent = (): JSX.Element => {
        if (data instanceof DogOriginModel) {
            return (
                <div className="controlled-width">
                    <h1 className="controlled-width__origin-info-title">Eredete</h1>
                    {Parser(data.long_desc)}
                </div>
            );
        }

        return (
            <div className="controlled-width">
                <h1 className="controlled-width__origin-info-title">Fajta leírás</h1>
                {Parser(data.long_desc)}
            </div>
        );
    };

    return (
        <div className={containerClassName.join(" ")}>
            <div className={isOpen ? "full-width-line" : "do-not-show"}>
                <button className="full-width-line__back-button" onClick={closingMoreOriginInfo}>
                    VISSZA
                </button>
            </div>
            {getContent()}
        </div>
    );
};
