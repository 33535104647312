import React, { useEffect } from "react";
import PageController from "./components/MainPage/Controller/MainPageController";
import EventController from "./components/ActualEvent/Controller/EventController";
import EventsController from "./components/EventsPage/Controller/EventsController";
import SpecDogController from "./components/SpecDogPage/Controller/SpecDogController";
import { DataContextWrapper } from "./contexts/Data.context";
import { Switch, Route, withRouter } from "react-router-dom";
import "./Utils/LessAccessories/base.css";
import OrganisationsController from "./components/Organisations/Controller/Organisations.controller";

function App(props: any) {
    useEffect(() => {
        window.scroll(0, 0);
    }, [props.history.location.pathname]);

    return (
        <DataContextWrapper>
            <div className="App">
                <Switch>
                    <Route exact path="/spec-dog/:dogID" component={SpecDogController} />
                    <Route exact path="/events" component={EventsController} />
                    <Route exact path="/events/:eventID" component={EventController} />
                    <Route exact path="/organisations" component={OrganisationsController} />
                    <Route exact path="/" component={PageController} />
                    <Route exact path="*" component={PageController} />
                </Switch>
            </div>
        </DataContextWrapper>
    );
}

export default withRouter(App);
