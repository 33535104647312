import * as React from "react";
import "./Less/Tabs.css";

import mindLogo from "../../../assets/elme.png";
import relationLogo from "../../../assets/kapcsolat.png";
import { DogModel } from "../Model/Dog.model";

interface IProps {
    dog: DogModel;
    bg: string;
}

const tabPhysiqueView: React.FC<IProps> = ({ dog, bg }) => {
    return (
        <div className="tabs-container">
            <ul className="property-section property-section--ab">
                <li className="list-item list-item--1-ab">
                    <div className="logo-and-title logo-and-title--ab">
                        <img className="tabs-logo" src={mindLogo} alt="mindLogo" />
                        <h3 className="tabs-title tabs-title--ab">Elme</h3>
                    </div>
                    <p className="property property--1-ab">Taníthatóság</p>
                    <p className="property property--2-ab">Ugatósság</p>
                    <p className="property property--3-ab">Mozgás igény</p>

                    <p className="value value--1-ab">{dog.teaching_authority}</p>
                    <p className="value value--2-ab">{dog.barking}</p>
                    <p className="value value--3-ab">{dog.movement_need}</p>
                </li>
                <li className="list-item list-item--2-ab">
                    <div className="logo-and-title logo-and-title--ab">
                        <img className="tabs-logo" src={relationLogo} alt="relationLogo" />
                        <h3 className="tabs-title tabs-title--ab">Kapcsolat</h3>
                    </div>
                    <p className="property property--1-ab">Állatokkal</p>
                    <p className="property property--2-ab-wider">Gyerekekkel</p>
                    <p className="property property--3-ab-smaller">Lakásban tartható</p>
                    <p className="value value--1-ab">{dog.relation_animal}</p>
                    <p className="value value--2-ab-wider">{dog.relation_child}</p>
                    <p className="value value--3-ab-smaller">{dog.kept_indoor}</p>
                </li>
            </ul>
            <div className="image-section">
                <img className="property-image" src={bg} alt="dog-abi" />
            </div>
        </div>
    );
};

export default tabPhysiqueView;
