import { IDynamicObject, ModelConstructor } from "xa-generics";

export class DogListModel {
    constructor(data?: IDynamicObject) {
        if (!data) return;
        ModelConstructor(data, this);
    }

    dog_id: string = "";
    name: string = "";
    short_desc: string = "";
    menu_img: string = "";
    image: string = "";
}
