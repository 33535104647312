import { IDynamicObject, ModelConstructor } from "xa-generics";

export class BlogEventModel {
    constructor(data?: IDynamicObject) {
        if (!data) return;
        ModelConstructor(data, this);
        this.published_at = this.published_at.replace(/-/g, "/");
    }

    blog_id: string = "";
    title: string = "";
    published_at: string = "";
    short_desc: string = "";
    hero_img: string = "";
}
