import { IDynamicObject, ModelConstructor } from "xa-generics";

export class DogFeaturesModel {
    constructor(data?: IDynamicObject) {
        if (!data) return;
        ModelConstructor(data, this);
    }

    dog_id: string = "";
    short_desc: string = "";
    long_desc: string = "";
    background: string = "";
}
