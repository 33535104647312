import * as React from "react";
import { useHistory } from "react-router-dom";
import { DataContext } from "../../../contexts/Data.context";
import { DogListModel } from "../../../models/DogList.model";
import "./Less/MoreDogBreeds.css";

interface IProps {
    currentDogID: string;
}

export const MoreDogBreedsView: React.FC<IProps> = (props) => {
    const history = useHistory();
    const { dogs } = React.useContext(DataContext);

    const indexOfCurrent: number = dogs.findIndex((dog) => dog.dog_id === props.currentDogID);
    if (indexOfCurrent < 0) return null;

    const dogCards: DogListModel[] = [];
    if (indexOfCurrent === 0) {
        //Next two dogs in list
        if (dogs[indexOfCurrent + 1]) dogCards.push(dogs[indexOfCurrent + 1]);
        if (dogs[indexOfCurrent + 2]) dogCards.push(dogs[indexOfCurrent + 2]);
    } else if (indexOfCurrent === dogs.length - 1) {
        //Prev two dogs in list
        if (dogs[indexOfCurrent - 1]) dogCards.push(dogs[indexOfCurrent - 1]);
        if (dogs[indexOfCurrent - 2]) dogCards.push(dogs[indexOfCurrent - 2]);
    } else {
        //Prev and next dogs in list
        if (dogs[indexOfCurrent - 1]) dogCards.push(dogs[indexOfCurrent - 1]);
        if (dogs[indexOfCurrent + 1]) dogCards.push(dogs[indexOfCurrent + 1]);
    }

    return (
        <section className="more-dogs-section">
            <div className="title-of-more-dogs">További kutyafajták</div>
            <div className="cards-container">
                {dogCards.map((card) => (
                    <div key={card.dog_id} className="more-dog-card">
                        <div className="more-dog-card__image-container">
                            <img
                                className="more-dog-card__image"
                                src={card.image}
                                alt={card.name}
                            />
                        </div>
                        <div className="more-dog-card__data">
                            <h3 className="more-dog-card__data--title">{card.name}</h3>
                            <button
                                className="more-dog-card__data--button"
                                onClick={() => {
                                    history.push(`/spec-dog/${card.dog_id}`);
                                }}
                            >
                                Bővebben
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};
