import { IDynamicObject, ModelConstructor } from "xa-generics";

export class DogModel {
    constructor(data?: IDynamicObject) {
        if (!data) return;
        ModelConstructor(data, this);
    }

    dog_id: string = "";
    name: string = "";
    short_desc: string = "";
    stature: string = "";
    height_male: string = "";
    height_female: string = "";
    weight_male: string = "";
    weight_female: string = "";
    pelage_color: string = "";
    pelage_type: string = "";
    pelage_desc: string = "";
    pelage_care: string = "";
    teaching_authority: string = "";
    barking: string = "";
    movement_need: string = "";
    relation_animal: string = "";
    relation_child: string = "";
    kept_indoor: string = "";
    description: string = "";
    order: string = "";
}
