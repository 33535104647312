import * as React from "react";
import { IHistory, ILoading, ILocation, IMatch } from "xa-generics";
import { DogMiscModel } from "../Model/DogMisc.model";
import { FullDogModel } from "../Model/FullDog.model";
import { DataContext } from "../../../contexts/Data.context";
import { SpecDogView } from "../View/SpecDogView";

interface IProjectProps {
    history: IHistory;
    location: ILocation;
    match: IMatch<{ dogID: string }>;
    staticContext?: any;
}

export interface ISpecDogControllerState {
    dog: FullDogModel;
    misc: DogMiscModel | null;
    showDogBreeds: boolean;
    showSlider: boolean;
    showMoreAboutOrigin: boolean;
    showMoreBreedDesc: boolean;
    loading: ILoading;
}

class SpecDogPageController extends React.Component<IProjectProps, ISpecDogControllerState> {
    state: ISpecDogControllerState = {
        showDogBreeds: false,
        showSlider: false,
        showMoreAboutOrigin: false,
        showMoreBreedDesc: false,
        loading: true,
        dog: new FullDogModel(),
        misc: null
    };

    componentDidMount = () => {
        this.init();

        document.body.style.overflowY = "scroll";
    };

    componentDidUpdate(prevProps: IProjectProps, prevState: ISpecDogControllerState) {
        if (this.props.match.params.dogID !== prevProps.match.params.dogID) {
            this.init();
        }
    }

    init = () => {
        const dogID: string = this.props.match.params.dogID;
        if (!(dogID in this.context.dogsByID)) {
            this.context.getDogByID(dogID, () =>
                this.setState({
                    loading: false,
                    dog: this.context.dogsByID[dogID].dog,
                    misc: this.context.dogsByID[dogID].misc
                })
            );
        } else {
            this.setState({
                loading: false,
                dog: this.context.dogsByID[dogID].dog,
                misc: this.context.dogsByID[dogID].misc
            });
        }
    };

    dogBreedsClicked = () => {
        if (window.innerWidth <= 600) {
            document.body.style.overflow = "hidden";
        }
        this.setState({ showDogBreeds: true });
    };

    closeDogBreeds = () => {
        document.body.style.overflowY = "scroll";
        this.setState({ showDogBreeds: false });
    };

    sliderHandler = (value: boolean) => {
        this.setState({ showSlider: value });
    };

    openMoreAboutOrigin = () => {
        document.body.style.overflow = "hidden";
        this.setState({ showMoreAboutOrigin: true });
    };

    openMoreBreedDesc = () => {
        document.body.style.overflow = "hidden";
        this.setState({ showMoreBreedDesc: true });
    };

    closingMoreOriginInfo = () => {
        document.body.style.overflowY = "scroll";
        this.setState({ showMoreAboutOrigin: false, showMoreBreedDesc: false });
    };

    render() {
        return (
            <SpecDogView
                {...this.state}
                closeDogBreeds={this.closeDogBreeds}
                sliderHandler={this.sliderHandler}
                openMoreAboutOrigin={this.openMoreAboutOrigin}
                openMoreBreedDesc={this.openMoreBreedDesc}
                closingMoreOriginInfo={this.closingMoreOriginInfo}
            />
        );
    }
}

SpecDogPageController.contextType = DataContext;

export default SpecDogPageController;
