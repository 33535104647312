import * as React from "react";
import Navbar from "../../Layout/Navbar/Controller/NavbarController";
import Footer from "../../Layout/Footer/FooterView";
import FurTab from "./TabFurView";
import Loading from "../../Loading/View/Loading.view";
import DogBreeds from "../../DogBreeds/DogBreedsView";
import AbilityTab from "./TabAbilityView";
import DogDescView from "./DogDescView";
import PhysiqueTab from "./TabPhysiqueView";
import SliderController from "../../Slider/Controller/SliderController";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { ISpecDogControllerState } from "../Controller/SpecDogController";
import { MoreDogBreedsView } from "./MoreDogBreedsView";
import { OriginView } from "../../MoreAboutOrigin/View/OriginView";
import "./Less/SpecDogView.css";

interface IProps extends ISpecDogControllerState {
    showDogBreeds: boolean;
    showSlider: boolean;
    showMoreAboutOrigin: boolean;
    showMoreBreedDesc: boolean;
    closeDogBreeds: () => void;
    sliderHandler: (showSlider: boolean) => void;
    openMoreAboutOrigin: () => void;
    openMoreBreedDesc: () => void;
    closingMoreOriginInfo: () => void;
}

export const SpecDogView: React.FC<IProps> = (props) => {
    if (!props.misc || props.loading) return <Loading />;

    console.log("specdog: ", props);

    return (
        <div className="page-content">
            <Navbar navbarComplexity="complex" dogName={props.dog.dog.name} />

            <DogBreeds sliding={true} />

            <OriginView
                isOpen={props.showMoreAboutOrigin}
                data={props.misc.origin}
                closingMoreOriginInfo={props.closingMoreOriginInfo}
            />

            <OriginView
                isOpen={props.showMoreBreedDesc}
                data={props.misc.features}
                closingMoreOriginInfo={props.closingMoreOriginInfo}
            />
            <div className="top-push-down"></div>
            <header className="spec-top-section" id="intro">
                <img className="spec-bg-image" src={props.misc.general.hero_img} alt="dog" />
                <div className="intro-text">
                    <h2 className="intro-text__title-top">{props.misc.general.slogan}</h2>
                    <h1 className="intro-text__title-bottom">{props.dog.dog.name}</h1>
                </div>
            </header>
            <section className="original-section" id="origin">
                <DogDescView
                    backGroundImage={props.misc.origin.background}
                    sectionTitle="Eredete"
                    upperSide={true}
                    sectionDesc={props.misc.origin.short_desc}
                    open={props.openMoreAboutOrigin}
                />
            </section>
            <div id="props"></div>
            <section className="property-container">
                <h1 className="property-title">Jellemzők</h1>
                <Tabs className="custom-react-tabs">
                    <TabList>
                        <Tab>FIZIKUM</Tab>
                        <Tab>SZŐRZET</Tab>
                        <Tab>ELME, KAPCSOLAT</Tab>
                    </TabList>
                    <TabPanel>
                        <PhysiqueTab bg={props.misc.properties.physique_bg} dog={props.dog.dog} />
                    </TabPanel>
                    <TabPanel>
                        <FurTab bg={props.misc.properties.fur_bg} dog={props.dog.dog} />
                    </TabPanel>
                    <TabPanel>
                        <AbilityTab bg={props.misc.properties.mind_bg} dog={props.dog.dog} />
                    </TabPanel>
                </Tabs>
            </section>
            <section className="original-section" id="desc">
                <DogDescView
                    backGroundImage={props.misc.features.background}
                    sectionTitle="Fajta leírás"
                    upperSide={false}
                    sectionDesc={props.misc.features.short_desc}
                    open={props.openMoreBreedDesc}
                />
            </section>
            {props.showSlider ? (
                <SliderController images={props.dog.images} sliderHandler={props.sliderHandler} />
            ) : (
                <div
                    className="gallery-section"
                    id="gallery"
                    onClick={() => props.sliderHandler(true)}
                >
                    {props.dog.images.slice(0, 3).map((img) => (
                        <img
                            key={img.image_id}
                            src={img.image}
                            alt="single-dog"
                            className="single-image"
                        ></img>
                    ))}
                    <div className="button-of-gallery">Galéria megtekintése</div>
                </div>
            )}
            <div className="page-content__other-dog-breeds-section">
                <MoreDogBreedsView currentDogID={props.dog.dog.dog_id} />
            </div>
            <Footer />
        </div>
    );
};
