import * as React from "react";
import { IError, ILoading } from "xa-generics";
import { BlogEventModel } from "../Model/BlogEvent.model";
import { EventDAO } from "../DAO/Event.dao";
import EventsView from "../View/EventsView";

interface IProjectProps {
    history: any;
    location: any;
    match: any;
    staticContext?: any;
}

interface IProjectState {
    events: BlogEventModel[];
    error: IError;
    loading: ILoading;
    showMontage: boolean;
}

class EventsController extends React.Component<IProjectProps, IProjectState> {
    state: IProjectState = {
        events: [],
        showMontage: false,
        loading: true,
        error: null
    };
    componentDidMount() {
        this.loadEvents();
    }

    loadEvents = (): void => {
        if (!this.state.loading) this.setState({ loading: true });
        EventDAO.loadEvents()
            .then((data) => this.setState({ events: data }))
            .catch((error: IError) => this.setState({ error }))
            .finally(() => this.setState({ loading: false }));
    };

    dogBreedsClicked = () => {
        if (window.innerWidth <= 600) {
            document.body.style.overflow = "hidden";
        }
        this.setState({ showMontage: true });
    };

    closeMontage = () => {
        document.body.style.overflowY = "scroll";
        this.setState({ showMontage: false });
    };

    render() {
        return (
            <EventsView
                events={this.state.events}
                showMontage={this.state.showMontage}
                closeMontage={this.closeMontage}
            />
        );
    }
}

export default EventsController;
